exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bigquery-a-sql-tsx": () => import("./../../../src/pages/bigquery-a-sql.tsx" /* webpackChunkName: "component---src-pages-bigquery-a-sql-tsx" */),
  "component---src-pages-datova-architektura-tsx": () => import("./../../../src/pages/datova-architektura.tsx" /* webpackChunkName: "component---src-pages-datova-architektura-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jak-na-dokumentaci-tsx": () => import("./../../../src/pages/jak-na-dokumentaci.tsx" /* webpackChunkName: "component---src-pages-jak-na-dokumentaci-tsx" */),
  "component---src-pages-konfigurace-ga-4-a-jeji-specifika-tsx": () => import("./../../../src/pages/konfigurace-ga4-a-jeji-specifika.tsx" /* webpackChunkName: "component---src-pages-konfigurace-ga-4-a-jeji-specifika-tsx" */),
  "component---src-pages-marketing-mix-modelling-tsx": () => import("./../../../src/pages/marketing-mix-modelling.tsx" /* webpackChunkName: "component---src-pages-marketing-mix-modelling-tsx" */),
  "component---src-pages-nastaveni-reportingu-v-ga-4-tsx": () => import("./../../../src/pages/nastaveni-reportingu-v-ga4.tsx" /* webpackChunkName: "component---src-pages-nastaveni-reportingu-v-ga-4-tsx" */),
  "component---src-pages-navrh-mereni-tsx": () => import("./../../../src/pages/navrh-mereni.tsx" /* webpackChunkName: "component---src-pages-navrh-mereni-tsx" */),
  "component---src-pages-obchodni-podminky-tsx": () => import("./../../../src/pages/obchodni-podminky.tsx" /* webpackChunkName: "component---src-pages-obchodni-podminky-tsx" */),
  "component---src-pages-ochrana-osobnich-udaju-tsx": () => import("./../../../src/pages/ochrana-osobnich-udaju.tsx" /* webpackChunkName: "component---src-pages-ochrana-osobnich-udaju-tsx" */),
  "component---src-pages-pohled-marketera-vyhodnocovani-tsx": () => import("./../../../src/pages/pohled-marketera-vyhodnocovani.tsx" /* webpackChunkName: "component---src-pages-pohled-marketera-vyhodnocovani-tsx" */),
  "component---src-pages-pohled-produktu-cro-ab-testovani-tsx": () => import("./../../../src/pages/pohled-produktu-cro-ab-testovani.tsx" /* webpackChunkName: "component---src-pages-pohled-produktu-cro-ab-testovani-tsx" */),
  "component---src-pages-privacy-a-sber-1st-party-dat-tsx": () => import("./../../../src/pages/privacy-a-sber-1st-party-dat.tsx" /* webpackChunkName: "component---src-pages-privacy-a-sber-1st-party-dat-tsx" */),
  "component---src-pages-server-side-mereni-a-jeho-specifika-tsx": () => import("./../../../src/pages/server-side-mereni-a-jeho-specifika.tsx" /* webpackChunkName: "component---src-pages-server-side-mereni-a-jeho-specifika-tsx" */),
  "component---src-pages-zavadeni-datove-kultury-tsx": () => import("./../../../src/pages/zavadeni-datove-kultury.tsx" /* webpackChunkName: "component---src-pages-zavadeni-datove-kultury-tsx" */)
}

